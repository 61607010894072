import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import Switch from "react-switch";
import { Accordion } from 'react-bootstrap';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';

const CampaignsStartPage = () => {
    const [campaign, setCampaign] = useState({});
    const [copySuccess, setCopySuccess] = useState('');
    const { id } = useParams();
    const [subscriptions, setSubscriptions] = useState([]);
    
    const navigate = useNavigate();

    useEffect(() => {

        if(!Glitch.util.Session.isLoggedIn()) {
            var currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }

        Glitch.api.Campaigns.view(id).then(response => {
            setCampaign(response.data.data);

            Glitch.api.Subscriptions.listCommunityInfluencerSubscriptions(response.data.data.community_id).then(response => {
                setSubscriptions(response.data.data);
            }).catch(error => {
                console.error(error);
            });
        }).catch(error => {
            console.error(error);
        });
    }, [id]);

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    const startSourcing = () => {
        Glitch.api.Campaigns.update(id, { activate_sourcing: true }).then((response) => {
            navigate(Navigate.campaignsFindInfluencers(id));
        }).catch(error => {
            console.error(error);
        });
    }

    const shareLink = () => {
        let link = `${window.location.origin}${Navigate.influencersViewCampaignPage(campaign.id)}`;
        navigator.clipboard.writeText(link).then(() => {
            setCopySuccess('Link copied successfully!');
            setTimeout(() => setCopySuccess(''), 3000);
        }, () => {
            setCopySuccess('Failed to copy the link.');
            setTimeout(() => setCopySuccess(''), 3000);
        });
    };

    // Function to toggle campaign active status
    const toggleCampaignStatus = async (campaignId, isActive) => {
        Glitch.api.Campaigns.update(campaignId, { is_active: isActive }).then(response => {
            let updatedCampaign = response.data.data;
            setCampaign(updatedCampaign);
        }).catch(error => {
            console.error(error);
        });
    };

    return (
        <>
            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title mt-3">Checklist & Start Sourcing</h2>
                        <p className="lead">Review The Checklist & Start Sourcing Influencers</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5">
                <CampaignNavbar campaignId={id} />
            </div>

            <div className="container my-5">
                <div className="card">
                    <div className="card-header bg-secondary">
                        <h2>Review The Materials Below</h2>
                    </div>
                    <div className="card-body text-dark text-black">
                        <section className="mt-1 background-black">
                            <div className="ratio ratio-16x9 mb-3">
                                <iframe
                                    src="https://www.youtube.com/embed/Dh-lYPsmtP8"
                                    allowFullScreen
                                    title="YouTube Video"
                                ></iframe>
                            </div>
                        </section>
                        <section className="mt-1">

                            <hr />
                            <h3 className="text-black text-center mb-4">Checklist Steps</h3>
                            <Accordion defaultActiveKey="0">
                                {[
                                    {
                                        title: "Step 1: Videos and Images",
                                        content: (
                                            <>
                                                <h5 className='text-black'>Check Videos and Image</h5>
                                                <p>Influencers are visual people first! Make sure you have added images and especially videos to your campaign to provide a more interactive presentation of your game. Use the YouTube Video section when editing a campaign.</p>
                                                <a className='btn btn-info btn-sm mt-2' target='_blank' href={Navigate.campaignsUpdateMediaPage(campaign.id)}><i className="fas fa-photo-video"></i> Update Media</a>
                                            </>
                                        ),
                                        image: "/assets/images/publishers/campaign_media.gif",
                                    },
                                    {
                                        title: "Step 2: Add Your Links",
                                        content: (
                                            <>
                                            <   h5 className='text-black'>Check Links To Be Tracked</h5>
                                                <p>Influencers can share links to your game's webpage and other websites. Ensure your links in the 'Manage Links' section are up-to-date with where you want influencers to send people. We will handle the UTMs and other tracking information for you.</p>
                                                <a className='btn btn-info btn-sm mt-2' target='_blank' href={Navigate.campaignsUpdateLinksPage(campaign.id)}><i className="fas fa-link"></i> Update Links</a>
                                            </>
                                        ),
                                        image: "/assets/images/publishers/campaign_links.gif",
                                    },
                                    {
                                        title: "Step 3: Check Over All Fields",
                                        content: (
                                            <>
                                                <h5 className='text-black'>Check All Fields</h5>
                                                <p>Review all the text on your campaign page. If something seems off, update your campaign in the campaign section.</p>
                                                <a className='btn btn-info btn-sm mt-2' target='_blank' href={Navigate.campaignsViewPage(campaign.id)}><i className="far fa-check-square"></i> Check Fields</a>
                                            </>
                                        ),
                                        image: null,
                                    },
                                    {
                                        title: "Step 4: Activate Your Campaign",
                                        content: (
                                            <>
                                                <h5 className='text-black'>Make Sure Your Campaign is Active</h5>
                                                <p>For influencers to find your campaign, it must be active. On the main Campaigns page, ensure the "Active Toggle" is green and you're good to go.</p>
                                                <br />
                                                <p><strong className='mb-3'>Current Status:</strong></p>
                                                <div className="ms-2 d-flex align-items-center mt-3">
                                                    <Switch
                                                        checked={campaign.is_active}
                                                        onChange={() => toggleCampaignStatus(campaign.id, !campaign.is_active)}
                                                        className='text-right'
                                                    /> &nbsp;&nbsp;&nbsp;{(campaign.is_active) ? 'Active' : 'Inactive'}
                                                </div>
                                            </>
                                        ),
                                        image: "/assets/images/publishers/campaign_active.gif",
                                    },
                                    {
                                        title: "Step 5: Share Your Campaign",
                                        content: (
                                            <>
                                                <h5 className='text-black'>Share Link To Campaign</h5>
                                                <p>Your campaign has a Share Link that you can share with others and invite them. Place the Share Link on Discord, Reddit, Facebook, Twitter, and other places to successfully promote your campaign.</p>
                                                <br />
                                                <p><strong>Share Link:</strong> &nbsp;&nbsp; <button onClick={shareLink} type='button' className='btn btn-info btn-sm'><i className="fa fa-copy"></i> Copy</button></p>
                                                {copySuccess && <div className="alert alert-success mt-2">{copySuccess}</div>}
                                                <small>Share this link with influencers</small>
                                            </>
                                        ),
                                        image: "/assets/images/publishers/campaign_share.gif",
                                    },
                                    {
                                        title: "Step 6: Use the Database & AI",
                                        content: (
                                            <>
                                                <h5 className='text-black'>Find Releveant Influencers</h5>
                                                <p>Use our influencer database to search for influencers! You can easily sort through influencers on different platforms and invite them to your campaign. We will handle the email reach-out. Additionally, if you have AI active in Sourcing Management, it will reach out for you.</p>
                                            </>
                                        ),
                                        image: "/assets/images/publishers/ai_sourcing.gif",
                                    },
                                    {
                                        title: "Step 7: Respond Quickly and Be Ready to Negotiate",
                                        content: (
                                            <>
                                                <h5 className='text-black'>Engage With Influencers</h5>
                                                <p>When influencers reach out, respond to them quickly! Be prepared to negotiate on terms for their time and creative skills.</p>
                                            </>
                                        ),
                                        image: null,
                                    }
                                ].map((step, index) => (
                                    <Accordion.Item eventKey={index.toString()} key={index}>
                                        <Accordion.Header>
                                            <span style={{ color: 'white' }}>{step.title}</span>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="d-flex flex-column flex-md-row align-items-center">
                                                <div className="w-100 w-md-75">
                                                    {step.content}
                                                </div>
                                                {step.image && (
                                                    <div className="w-100 w-md-25 text-center">
                                                        <img src={step.image} alt={step.title} className="img-fluid my-2 my-md-0" />
                                                    </div>
                                                )}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </section>

                        <section className="mb-4 mt-5">
                            <h3 className="text-black text-center mb-4">Start Sourcing Creators</h3>
                            <p>Next you can start sourcing creators that fit your needs. Review your target information below, and if everything looks correct, click the 'Begin Sourcing' button to promote and connect creators to your campaign. You will only pay for creators that you choose to work with.</p>
                            {campaign.target_audience && (
                                <p><strong>Target Audience:</strong> <span dangerouslySetInnerHTML={createMarkup(campaign.target_audience)} /></p>
                            )}
                            {campaign.target_age_minimum && (
                                <p><strong>Age Min:</strong> {campaign.target_age_minimum}</p>
                            )}
                            {campaign.target_age_maximum && (
                                <p><strong>Age Max:</strong> {campaign.target_age_maximum}</p>
                            )}
                            {campaign.countries && campaign.countries.length > 0 && (
                                <p><strong>Countries:</strong> {campaign.countries.map((country) => (
                                    <span key={country.id} className="badge bg-primary m-1">
                                        {country?.name}
                                    </span>
                                ))}</p>
                            )}
                            {campaign.genders && campaign.genders.length > 0 && (
                                <p><strong>Gender:</strong> {campaign.genders.map((gender) => (
                                    <span key={gender.id} className="badge bg-secondary m-1">
                                        {gender.name}
                                    </span>
                                ))}</p>
                            )}
                            { subscriptions.length === 0 && (
                                <div className="alert alert-danger m-0" style={{ borderTopLeftRadius: '0', borderTopRightRadius: '0', borderBottomLeftRadius: '.25rem', borderBottomRightRadius: '.25rem' }} role="alert">
                                    <i className="fas fa-exclamation-triangle"></i> You do not have an active subscription. You will be able to use the database for free, but you will not be able to invite influencers or use the AI tools. Please <Link target="_blank" className='text-primary' to={Navigate.communitiesSubscribePage()}>select a plan here</Link>.
                                </div>
                            )}
                            <div className='text-center mt-4'>
                                <button className="btn btn-lg btn-success" type="button" onClick={startSourcing}>Start Finding Influencers</button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default CampaignsStartPage;
