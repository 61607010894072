import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Glitch from 'glitch-javascript-sdk';


const PublisherOnboardingStep3Page = () => {

    const [community, setCommunity] = useState({});

    const navigate = useNavigate();
    
    const handleCreateCampaign = () => {
        navigate(Navigate.campaignsCreatePage());
    };

    const handleCreateCommunity = () => {
        navigate(Navigate.communitiesGettingStartedPage(community.id));
    };

    useEffect(() => {

        Glitch.api.Communities.list({ roles: [1, 2], order_by : 'created_at' }).then(response => {

            if (response.data.data.length > 0) {
                setCommunity(response.data.data[0]);
            }

        }).catch(error => {

        });
        
    }, []);

    return (
        <div className="d-flex flex-column justify-content-center align-items-center text-center h-100" style={{ gap: '20px' }}>
            <div className='text-white  mt-5'>
                <h2>Registration Final Step</h2>
                <p className="lead text-center">Congratulations on completing your registration! Watch the brief intro video below and decide what you'd like to do next.</p>
            </div>

            <div className="row justify-content-center w-100">
                <div className="col-md-8">
                    <div className="ratio ratio-16x9 border border-white border-2">
                        <iframe
                            src="https://www.youtube.com/embed/lKbuXSjl_GA"
                            title="Getting Started with Glitch"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>

            <hr />

            <div>
                <h3>What would you like to do next?</h3>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-center mb-5" style={{ gap: '30px' }}>
                <button 
                    type="button" 
                    className="btn btn-primary btn-lg" 
                    style={{ padding: '80px 80px', fontSize: '20px', width: 'calc(60% - 10px)', maxWidth: '350px' }}
                    onClick={handleCreateCampaign}>
                    <i className="fas fa-2x fa-bullhorn"></i><br />Start A<br /> Campaign
                </button>
                <button 
                    type="button" 
                    className="btn btn-secondary btn-lg" 
                    style={{ padding: '80px 80px', fontSize: '20px', width: 'calc(60% - 10px)', maxWidth: '350px' }}
                    onClick={handleCreateCommunity}>
                    <i className="fas fa-2x fa-users"></i> <br />Build A<br /> Community
                </button>
            </div>
            <div className='container mb-5'>
                <p className="lead text-center">Would you like to start an influencer campaign or begin building a community? <br /><br />An influencer campaign will help you find influencers to market your game. A community will allow you to build a forum-like system with posts, GIFs, videos, and streams where users can engage in an environment focused entirely on your game.</p>

            </div>

        </div>
    );
};

export default PublisherOnboardingStep3Page;
