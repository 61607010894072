import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faYoutube, faReddit, faTwitter, faFacebook, faTwitch } from '@fortawesome/free-brands-svg-icons';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { getInfluencerImage } from '../../../../util/InfluencerUtils';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';
import { Modal, Button, Form, Spinner, Alert, DropdownButton, Dropdown, Badge  } from 'react-bootstrap';
import Wysiwyg from '../../component/form/wysiwyg';
import { faEdit, faPlus, faSpinner, faTrash, faEye, faSave, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';
import Textarea from '../../component/form/textarea';
import CampaignRateCard from '../../component/section/campaigns/campaign_rate_card';



const DEFAULT_IMAGE = 'https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png';

const CampaignsViewInfluencerInvitePage = () => {
    const [influencer, setInfluencer] = useState(null);
    const [invite, setInvite] = useState({});
    const [campaign, setCampaign] = useState({});
    const { id, influencer_id } = useParams();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showCustomInviteModal, setShowCustomInviteModal] = useState(false);
    const [customEmail, setCustomEmail] = useState('');
    const [notes, setNotes] = useState([]);
    const [newNote, setNewNote] = useState('');
    const [editingNoteId, setEditingNoteId] = useState(null);
    const [editingNoteContent, setEditingNoteContent] = useState('');
    const [editingEmail, setEditingEmail] = useState(null);
    const [viewingEmail, setViewingEmail] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedStage, setSelectedStage] = useState('All');

    const stages = ['Contacting', 'Negotiating', 'Contracting', 'Finalizing', 'Finished', 'Lost'];

    const navigate = useNavigate();

    useEffect(() => {

        if(!Glitch.util.Session.isLoggedIn()) {
            var currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }
        
        Glitch.api.Campaigns.view(id).then(response => {
            setCampaign(response.data.data);

            Glitch.api.Subscriptions.listCommunityInfluencerSubscriptions(response.data.data.community_id).then(response => {
                setSubscriptions(response.data.data);
            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });

        fetchInvite();

        fetchInfluencer();
        getNotes();
    }, []);

    const fetchInvite = async () => {

        try {
            const response = await Glitch.api.Campaigns.viewInfluencerInvite(id, influencer_id);
            setInvite(response.data.data);
        } catch (error) {
            console.error('Error fetching influencer', error);
        }

    };

    const fetchInfluencer = async () => {
        try {
            const response = await Glitch.api.Influencers.viewInfluencer(influencer_id, { campaign_id: id });
            setInfluencer(response.data.data);
        } catch (error) {
            console.error('Error fetching influencer', error);
        }
    };

    const getNotes = () => {
        Glitch.api.Influencers.listNotes(influencer_id, { campaign_id: id }).then(response => {
            setNotes(response.data.data);
        }).catch(error => {
            console.error(error);
        });
    };

    const createNewNote = (note) => {
        Glitch.api.Influencers.createNote(influencer_id, { note: note, campaign_id: id }).then(response => {
            getNotes();
        }).catch(error => {
            console.error(error);
        });
    };

    const updateNote = (note_id, note) => {
        Glitch.api.Influencers.updateNote(influencer_id, note_id, { note: note }).then(response => {
            getNotes();
            setEditingNoteId(null);
            setEditingNoteContent('');
        }).catch(error => {
            console.error(error);
        });
    };

    const deleteNote = (note_id) => {
        if (window.confirm('Are you sure you want to delete this note?')) {
            Glitch.api.Influencers.deleteNote(influencer_id, note_id).then(response => {
                getNotes();
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const formatNumber = (num) => {
        return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
    };

    const formatExternalUrls = (urls) => {
        if (Array.isArray(urls)) {
            return urls.map(url => url.replace(/[\[\]'"]/g, '').trim()).filter(url => url && url !== 'None');
        }
        return [];
    };

    const getStatusBadge = (invite) => {
        if (invite.accepted === true) return <small><span className="badge bg-success small">Accepted</span></small>;
        if (invite.rejected === true) return <small><span className="badge bg-danger small">Declined</span></small>;
        return <small><span className="badge bg-warning small">Pending</span></small>;
    };

    const toggleEditingEmail = (emailIndex) => {
        setEditingEmail(editingEmail === `${emailIndex}` ? null : `${emailIndex}`);
    };

    const toggleViewingEmail = (emailIndex) => {
        setViewingEmail(viewingEmail === `${emailIndex}` ? null : `${emailIndex}`);
    };

    const handleSaveEmail = (invite, emailIndex) => {
        const data = {
            [`email_touch_point_${emailIndex + 1}_html`]: invite.email_touch_points[emailIndex].html,
            [`email_touch_point_${emailIndex + 1}_text`]: invite.email_touch_points[emailIndex].text,
        };
        updateInvite(invite.influencer_id, data);
        setEditingEmail(null);
    };

    const updateInvite = async (influencerID, data) => {
        setSaving(true);
        try {
            let response = await Glitch.api.Campaigns.updateInfluencerInvite(id, influencerID, data);
            setInvite(response.data.data);
        } catch (error) {
            console.error(error);
        }
        setSaving(false);
    };

    const handleWysiwygChange = (emailIndex, value) => {
        setInvite((prevInvite) => {
            const updatedInvite = { ...prevInvite };
            updatedInvite.email_touch_points[emailIndex].html = value;
            return updatedInvite;
        });
    };
    
    const handleTextChange = (emailIndex, value) => {
        setInvite((prevInvite) => {
            const updatedInvite = { ...prevInvite };
            updatedInvite.email_touch_points[emailIndex].text = value;
            return updatedInvite;
        });
    };


    const sanitizeEmailHtml = (html) => {
        return sanitizeHtml(html, {
            allowedTags: sanitizeHtml.defaults.allowedTags.filter(tag => tag !== 'html' && tag !== 'body' && tag !== 'footer'),
            allowedAttributes: {
                ...sanitizeHtml.defaults.allowedAttributes,
                a: ['href', 'name', 'target', 'class'],
                img: ['src', 'alt', 'class']
            }
        });
    };

    const finishInfluencer = async (influencerID) => {
        if (window.confirm("Are you sure you want to mark this campaign as finished? This will stop all email outreach.")) {
            Glitch.api.Campaigns.finishInfluencerInvite(id, influencerID).then(response => {
                setInvite(response.data.data);
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleStageChange = (influencerID, newStage) => {
        updateInvite(influencerID, { stage: newStage });
    };

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    if (!influencer) {
        return <p>Loading...</p>;
    }

    const externalUrls = formatExternalUrls(influencer.external_urls);

    const hasYouTubeData = influencer.youtube_link || influencer.youtube_custom_url || influencer.youtube_title || influencer.youtube_subscriber_count || influencer.youtube_video_count;

    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />

                <section className="pageheader-section-min">
                    <div className="container mt-4">
                        <Breadcrumbs items={[
                            { name: 'Campaigns', link: Navigate.campaignsPage() },
                            { name: campaign.name, link: Navigate.campaignsViewPage(campaign.id) },
                            { name: 'Find Influencer', link: Navigate.campaignsFindInfluencers(campaign.id) },
                            { name: 'View Influencer', link: Navigate.campaignsViewInfluencer(campaign.id, influencer_id) },
                        ]}
                        />
                        <div className="section-wrapper text-center">
                            <div className="pageheader-thumb mb-4">
                            </div>
                            <h2 className="pageheader-title">Review Invite</h2>
                            <p className="lead">Review the invite for the follower influencer below.</p>
                        </div>
                    </div>
                </section>

                <div className="container mt-5">
                    <CampaignNavbar campaignId={id} />
                </div>

                <div className="container mt-4">
                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Personal Information</h4>
                        </div>
                        <div className="card-body d-flex align-items-center">
                            <img
                                src={getInfluencerImage(influencer)}
                                alt={`${influencer.first_name}'s profile`}
                                className="img-thumbnail me-4"
                                style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                            />
                            <div>
                                <p><strong>Name:</strong> {influencer.first_name || influencer.instagram_username || influencer.youtube_title}</p>
                                <p><strong>Location:</strong> {influencer.location}</p>
                                <p><strong>Speaking Language:</strong> {influencer.speaking_language}</p>

                                <p><strong>Reachout Status:</strong> {invite.finished ? <span className="badge bg-secondary mr-2">Finished</span> : <span className="badge bg-primary mr-5">Ongoing</span>}
                                                {!invite.finished && (
                                                    <button className="btn btn-warning small btn-xs ms-3" onClick={() => finishInfluencer(invite.influencer_id)}>
                                                        <FontAwesomeIcon icon={faCheckCircle} /> Finish Campaign
                                                    </button>
                                                )}</p>

                                <p><strong>Current Stage:</strong>
                                    <div className="d-inline-block ms-2">
                                        <DropdownButton
                                            id={`stage-dropdown-${invite.influencer_id}`}
                                            title={invite?.stage?.charAt(0).toUpperCase() + invite?.stage?.slice(1)}
                                            onSelect={(e) => handleStageChange(invite.influencer_id, e)}
                                            className="d-inline"
                                            variant="primary"
                                            size="sm"
                                        >
                                            {stages.map(stage => (
                                                <Dropdown.Item key={stage} eventKey={stage.toLowerCase()}>
                                                    {stage}
                                                </Dropdown.Item>
                                            ))}
                                        </DropdownButton>
                                    </div>
                                </p>


                            </div>
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Reachout Management</h4>
                        </div>
                        <div className="card-body">

                            <div className="email-touch-points">

                                <p>We will attempt to contact the influencer by sending one email every 2-3 days, for a total of 5 emails. See the status of each email and manage what is being sent.</p>
                                {invite?.email_touch_points && invite?.email_touch_points.map((email, emailIndex) => (
                                    <div key={emailIndex} className="email-touch-point mb-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>Email {emailIndex + 1}</span>
                                            <span>Status: {email.opened ? 'Opened' : 'Not Opened'}</span>
                                            <span>Sent At: {email.sent_at ? moment(email.sent_at).format('MMMM Do YYYY, h:mm:ss a') : 'Not Sent'}</span>
                                            <span>Responded: {email.responded ? 'Yes' : 'No'}</span>
                                            {email.sent_at ? (
                                                <Button className="btn btn-secondary btn-sm" onClick={() => toggleViewingEmail(emailIndex)}>
                                                    <FontAwesomeIcon icon={faEye} /> View Email
                                                </Button>
                                            ) : (
                                                <Button className="btn btn-secondary btn-sm" onClick={() => toggleEditingEmail(emailIndex)}>
                                                    <FontAwesomeIcon icon={faEdit} /> Edit Email
                                                </Button>
                                            )}
                                        </div>
                                        {viewingEmail === `${emailIndex}` && (
                                            <div className="email-view mt-3">
                                                <h6>Email Content</h6>
                                                <div dangerouslySetInnerHTML={{ __html: sanitizeEmailHtml(invite.email_touch_points[emailIndex].html) }} />
                                            </div>
                                        )}
                                        {editingEmail === `${emailIndex}` && (
                                            <div className="email-edit-fields mt-3">
                                                <h6>Edit The HTML Version Of The Email</h6>
                                                <Wysiwyg
                                                    name={`email_touch_point_${emailIndex + 1}_html`}
                                                    value={invite.email_touch_points[emailIndex].html || ''}
                                                    onChange={(value) => handleWysiwygChange(emailIndex, value)}
                                                    placeholder="Edit HTML content"
                                                >{invite.email_touch_points[emailIndex].html}</Wysiwyg>
                                                <h6>Edit The Text Version Of The Email</h6>
                                                <Textarea
                                                    value={invite.email_touch_points[emailIndex].text || ''}
                                                    onChange={(e) => handleTextChange(emailIndex, e.target.value)}
                                                    placeholder="Edit text content"
                                                >{invite.email_touch_points[emailIndex].text || ''}</Textarea>
                                                <Button className="btn btn-primary btn-sm mt-2" onClick={() => handleSaveEmail(invite, emailIndex)}>
                                                    {saving ? (
                                                        <FontAwesomeIcon icon={faSpinner} spin />
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon icon={faSave} /> Save
                                                        </>
                                                    )}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Social Media Profiles</h4>
                        </div>
                        <div className="card-body">
                            <SocialMediaLink icon={faInstagram} data={influencer} platform="instagram" />
                            <SocialMediaLink icon={faTiktok} data={influencer} platform="tiktok" />
                            {hasYouTubeData && <SocialMediaLink icon={faYoutube} data={influencer} platform="youtube" />}
                            <SocialMediaLink icon={faTwitter} data={influencer} platform="twitter" />
                            <SocialMediaLink icon={faFacebook} data={influencer} platform="facebook" />
                            <SocialMediaLink icon={faReddit} data={influencer} platform="reddit" />
                            <SocialMediaLink icon={faTwitch} data={influencer} platform="twitch" />
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Content Details</h4>
                        </div>
                        <div className="card-body">
                            {externalUrls.length > 0 && (
                                <>
                                    <p><strong>External URLs:</strong></p>
                                    <ul>
                                        {externalUrls.map((url, index) => (
                                            <li key={index}><a href={url} target="_blank" rel="noopener noreferrer">{url}</a></li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            <p><strong>Has Link in Bio:</strong> {influencer.has_link_in_bio ? 'Yes' : 'No'}</p>
                            <p><strong>Has Brand Deals:</strong> {influencer.has_brand_deals ? 'Yes' : 'No'}</p>
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Campaign Scope & Expectations</h4>
                        </div>
                        <div className="card-body">
                            <Link className="btn btn-success me-2 btn-sm mb-3" to={Navigate.campaignsUpdateInfluencerInviteCompensation(id, invite?.influencer?.id)}>
                                    <i className="fas fa-money-bill-wave"></i> Update Compensation
                            </Link>
                            {invite.deliverables && <p><strong>Deliverables:</strong> <span dangerouslySetInnerHTML={createMarkup(invite.deliverables)} /></p>}
                            {invite.days_to_complete && <p><strong>Days To Complete:</strong> <span dangerouslySetInnerHTML={createMarkup(invite.days_to_complete)} /></p>}

                            <CampaignRateCard campaign={invite} user={influencer} />
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Notes</h4>
                        </div>
                        <div className="card-body">
                            {notes.map(note => (
                                <div key={note.id} className="mb-3 border p-3">
                                    {editingNoteId === note.id ? (
                                        <Wysiwyg id={`edit_note_${note.id}`} name="edit_note" onChange={(content) => setEditingNoteContent(content)} placeholder="Edit note">
                                            {editingNoteContent || note.note}
                                        </Wysiwyg>
                                    ) : (
                                        <div dangerouslySetInnerHTML={{ __html: note.note }} />
                                    )}
                                    <div className="mt-2">
                                        {editingNoteId === note.id ? (
                                            <>
                                                <Button variant="success" onClick={() => updateNote(note.id, editingNoteContent)}>Save</Button>
                                                <Button variant="secondary" onClick={() => setEditingNoteId(null)} className="ms-2">Cancel</Button>
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={faEdit} className="text-primary me-2" onClick={() => { setEditingNoteId(note.id); setEditingNoteContent(note.note); }} />
                                                <FontAwesomeIcon icon={faTrash} className="text-danger" onClick={() => deleteNote(note.id)} />
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div className="mt-4">
                                <Wysiwyg id="new_note" name="new_note" onChange={(content) => setNewNote(content)} placeholder="Write a new note">
                                    {newNote}
                                </Wysiwyg>
                                <Button variant="primary" onClick={() => createNewNote(newNote)} className="mt-2">Add Note</Button>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>

            {/* Success Modal */}


            {/* Error Modal */}
            <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-black'>{subscriptions.length === 0 ? 'Sign Up for a Subscription' : 'Update Payment Information'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-black'>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowErrorModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Custom Invite Modal */}

        </>
    );
};

const SocialMediaLink = ({ icon, data, platform }) => {
    if (!data[`${platform}_username`] && platform !== "youtube") {
        return null;
    }

    const formatNumber = (num) => {
        return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
    };

    const biographyField = `${platform}_biography`;
    const biography = data[biographyField];

    const hasYouTubeData = platform === "youtube" && (data.youtube_link || data.youtube_custom_url || data.youtube_title || data.youtube_subscriber_count || data.youtube_video_count);

    if (platform === "youtube" && !hasYouTubeData) {
        return null;
    }

    return (
        <div className="mb-3">
            <h6><FontAwesomeIcon icon={icon} className='text-black me-2' />
                {platform === "youtube" ? (
                    <Link to={data[`youtube_link`]} target="_blank">{data[`youtube_custom_url`] || data[`youtube_title`]}</Link>
                ) : (
                    <Link to={data[`${platform}_link`]} target="_blank">{data[`${platform}_username`]}</Link>
                )}
            </h6>
            <p><strong>Followers:</strong> {formatNumber(data[`${platform}_follower_count`] || data[`youtube_subscriber_count`])}</p>
            <p><strong>Engagement:</strong> {data[`${platform}_engagement_percent`] || data[`youtube_engagement_percent`]}%</p>
            {biography && <p><strong>Biography:</strong> {biography}</p>}
            {platform === "youtube" && hasYouTubeData && (
                <>
                    <p><strong>Video Count:</strong> {data[`youtube_video_count`]}</p>
                    <p><strong>Average Views:</strong> {data[`youtube_avg_views`]}</p>
                    <p><strong>Average Views (Shorts):</strong> {data[`youtube_avg_views_shorts`]}</p>
                    <p><strong>Average Views (Long):</strong> {data[`youtube_avg_views_long`]}</p>
                    <p><strong>Has Shorts:</strong> {data[`youtube_has_shorts`] ? 'Yes' : 'No'}</p>
                    <p><strong>Posting Frequency:</strong> {data[`youtube_posting_frequency`]} times per week</p>
                    <p><strong>Topics:</strong> {data[`youtube_topic_details`]?.join(', ')}</p>
                </>
            )}
        </div>
    );
};

export default CampaignsViewInfluencerInvitePage;
